.navbar {
	--bs-navbar-padding-y: 1rem;
    --navbar-logo-height: 90px;
}
.navbar-nav {
    --bs-nav-link-color: #000;
    --bs-nav-link-hover-color: var(--bs-primary);
}
.nav-link {
    --bs-nav-link-padding-y: 0.5rem;
}
.navbar .dropdown-menu {
    --bs-dropdown-border-width: 0;
    --bs-dropdown-border-radius: 0;
    --bs-dropdown-link-active-bg: var(--bs-primary);
}
.navbar + .section,
.navbar + .page-header {
    padding-top: 137px;
}
.navbar.sticky {
	--bs-navbar-padding-y: 0.5rem;
    --navbar-logo-height: 60px;
}
.navbar .logo {
    transition: ease all 400ms;
    height: var(--navbar-logo-height);
    display: flex;
    align-items: center;
}
.navbar .nav-item {
    position: relative;
}
.nav-item.active .nav-link {
    font-weight: 600;
    color: var(--bs-primary);
}
.dropdown-toggle:after {
    display: none !important;
}
.nav-phone {
    order: 0;
    background-color: var(--bs-primary);
    padding-left: 20px;
    margin-right: calc(-1*var(--bs-gutter-x)* .5);
    margin-left: calc(-1*var(--bs-gutter-x)* .5);
    color: #fff;
    text-align: center;
    position: relative;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.4rem;
}
.phone-number {
    font-size: 1.5rem;
    font-weight: bold;
}
@media (width >= 992px) {
    .navbar-brand {
        margin-left: var(--bs-navbar-brand-margin-end);
        display: flex;
        align-items: center;
    }
    .nav-item.active .nav-link:after {
        content: " ";
        width: 10px;
        height: 10px;
        background-color: var(--bs-primary);
        position: absolute;
        left: 50%;
        bottom: 0%;
        transform: translateX(-50%);
    }
    .navbar-expand-lg .navbar-collapse {
        flex-direction: column;
        align-items: end;
    }
    .nav-phone {
        order: 0;
        background-color: var(--bs-primary);
        padding-left: 20px;
        margin-right: calc(-1*var(--bs-gutter-x)* .5);
        padding-right: calc(var(--bs-navbar-nav-link-padding-x)+var(--bs-gutter-x)* .5);
        color: #fff;
        text-align: right;
        position: relative;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
        line-height: 1.4rem;
        margin-bottom:1rem;
    }

    .nav-phone:before {
        content: '';
        line-height: 0;
        font-size: 0;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 60px solid var(--bs-primary);
        border-left: 20px solid transparent;
        border-right: 0px solid var(--bs-primary);
        position: absolute;
        top: -20px;
        left: -20px;
    }
    .navbar-nav {
        order: 1;
    }
    .navbar.sticky.navbar-expand-lg .navbar-collapse {
        flex-direction: row;
        align-items: center;
    }
    .navbar.sticky.navbar-expand-lg .navbar-nav {
        order: 0;
    }
    .navbar.sticky.navbar-expand-lg .nav-phone {
        order: 1;
        margin-left: 20px;
        margin-bottom: 0;
    }
    .navbar .nav-item .dropdown-menu {
        top: 100%;
        right: 0;
    }
}
@media (width < 992px) {
    .navbar .nav-item {
        border-bottom: 1px solid #D8E1FF;
    }
    .navbar .nav-item .dropdown-menu {
        width: 100%;
        border: 0px none;
        background: transparent;
    }
    .navbar .nav-item .dropdown-menu li {
        text-align: center;
    }
    .navbar .nav-item .dropdown-menu li a {
        text-transform: uppercase;
        color: var(--bs-primary);
    }
    .navbar > .container-fluid .navbar-nav > .nav-item, .navbar > .container-fluid .navbar-nav > .nav-item > .nav-link {
        flex-direction: column;
        justify-content: center;
    }
}


@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link {   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}
.navbar > .container-fluid {
    align-items: normal !important;
}
.navbar > .container-fluid .navbar-nav {
    height: 100%;
}
.navbar > .container-fluid .navbar-nav > .nav-item {
    height: 100%;
	display: flex;
	align-items: center;
}
.navbar > .container-fluid .navbar-nav > .nav-item > .nav-link {
    height: 100%;
	display: flex;
	align-items: center;
}

.navbar .navbar-toggler {
	border: 0 none;
}